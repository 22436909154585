import React from "react"
import styles from "./404.module.css"

function error404() {
  return (
    <>
      <div className={styles["error-backdrop"]}>
        <div className={styles["error-container"]}>
          <h1 className={styles["error-header"]}>
            404<br />
            <small>Fehler</small>
          </h1>
          <div className={styles["error-message"]}>
            Hallo Wanderer, du bist einen Pfad gegangen, der ins Nichts führt. <br />
            Aber Gott sei Dank oder besser Göttin sei Dank ist Elkas nicht weit! <br />
            <a href={"https://maps.godus.de"}>Hier</a> geht es zurück zum Anfang.
          </div>
        </div>
      </div>
    </>
  )
}

export default error404